import { BaseElement, Editor, Node, Text as SlateText } from "slate";

import { MARTIAL_STATUS, ORDER_TYPE } from "../../constants";
import {
  makeBlock,
  makeDefinition,
  makeExecutionBlock,
  makeSubListBlock,
  makeText,
  makeWitnessBlock,
  Text,
  type Block,
} from "../../slate";
import {
  ASSET_TYPES_ENUM,
  EntityTypeEnum,
  getUserAssetResourceSchema,
  getUserEntityResourceSchema,
  UserAssetDefineTransformsMap,
  UserAssetDefinitionTransformsMap,
  UserDetailsDefineTransform,
  UserDetailsDefinitionTransform,
  UserEntityDefineTransformsMap,
  UserEntityDefinitionTransformsMap,
  UserResourceDetailsSchema,
} from "../../user-resource";
import { z } from "../../xzod";
import { OrderDocumentSchema } from "../index";
import {
  ALLOCATION_MODE_ENUM,
  TAmountOnlyAllocation,
  TPercentageOnlyAllocation,
} from "./common.schema";
import {
  BURIAL_INSTRUCTIONS_CHOICES,
  FUNERAL_INSTRUCTIONS_CHOICES,
  ValidatedWillFormSchema,
  WillFormSchema,
  WillLawerReviewChoices,
} from "./schema";

export const serializeDocumentToText = (
  nodes: Node[],
  parentNode?: BaseElement,
  index?: number,
): string => {
  const parentIsList = ["ordered-list", "bulleted-list"].includes(
    // @ts-ignore
    parentNode?.type,
  );
  return nodes
    .map((n: Node, i) => {
      if (Editor.isEditor(n)) {
        return serializeDocumentToText(n.children, n) + "\n";
      } else if (!SlateText.isText(n)) {
        return parentIsList
          ? `${i + 1}. ` + serializeDocumentToText(n.children, n, i + 1) + "\n"
          : serializeDocumentToText(n.children, n) + "\n";
      } else {
        return Node.string(n);
      }
    })
    .join("");
};

// export const serializeDocumentToText = (nodes: Node[]) => {
//   return nodes.map((n: Node) => Node.string(n)).join("\n");
// };

type TWillFormSchema = z.infer<typeof WillFormSchema>;
type TDefineAsset = (
  arg: string | null | undefined,
  hasSpecificDistributions: boolean,
) => Text[];
type TDefinePerson = (arg: string) => Text[];

interface TGenerateArg {
  entities: z.infer<typeof UserEntityResourceSchema>[];
  assets: z.infer<typeof UserAssetResourceSchema>[];
  resource: z.infer<typeof OrderDocumentSchema>;
  user: z.infer<typeof UserResourceDetailsSchema>;
  userId: string;
}

interface TUtils extends TGenerateArg {
  defineAsset: TDefineAsset;
  definePerson: TDefinePerson;
  getAssetTitle: ({ assetId }: { assetId: string | null }) => string;
  getAssetDescription: ({ assetId }: { assetId: string | null }) => string;
  data: z.infer<typeof ValidatedWillFormSchema>;
}

function isOfDistributionType<
  GenericType extends string,
  Union extends { assetAllocationType: GenericType },
  SpecificType extends GenericType,
>(val: SpecificType) {
  return (
    obj: Union,
  ): obj is Extract<Union, { assetAllocationType: SpecificType }> =>
    obj.assetAllocationType === val;
}

const UserAssetResourceSchema = getUserAssetResourceSchema({ _id: z.string() });
const UserEntityResourceSchema = getUserEntityResourceSchema({
  _id: z.string(),
});

export const makeInitialParagraphs = (slate: any[], utils: TUtils) => {
  slate.push(
    makeBlock(
      [
        makeText(`THIS IS THE LAST WILL AND TESTAMENT`, {
          bold: true,
          space: "end",
        }),
        makeText(`of me,`, { space: "end" }),
        ...utils.definePerson(utils.userId),
        makeText(
          `in respect of all my assets situated in the Republic of Singapore at the time of my death`,
          { space: "both" },
        ),
        ...makeDefinition(`Assets`, { period: true }),
      ],
      { type: "list-item" },
    ),
    makeBlock(
      [
        makeText(
          `I hereby revoke all former wills and testamentary dispositions made by me, and declare this to be my last will and testament`,
          { space: "end" },
        ),
        ...makeDefinition(`Will`, { space: "end" }),
        makeText(
          `and that this Will and any codicil to it shall be construed and take effect in accordance with the laws of the Republic of Singapore`,
          { period: true },
        ),
      ],
      { type: "list-item" },
    ),
  );
};

export const makeExecutorParagraphs = (slate: any[], utils: TUtils) => {
  const { executors = [], substituteExecutors = [] } = utils.data;

  if (executors.length === 0) return;

  if (executors.length === 1) {
    slate.push(
      makeBlock(
        [
          makeText(`I appoint`, { space: "end" }),
          ...utils.definePerson(executors[0] || ""),
          makeText(`to be the sole Executor and Trustee of this Will`, {
            space: "both",
          }),
          ...makeDefinition("Executor", {
            period: true,
            space: "start",
          }),
        ],
        { type: "list-item" },
      ),
    );
  }

  if (executors.length > 1) {
    slate.push(
      makeBlock(
        [
          makeText(
            `I appoint the following persons as joint executors and trustees of this Will`,
            { space: "end" },
          ),
          ...makeDefinition([
            `each my`,
            `Executor`,
            `and collectively my`,
            `Executors`,
          ]),
          makeText(`:`),
        ],
        { type: "list-item" },
      ),
      makeSubListBlock(
        executors.map((e: string) =>
          makeBlock(utils.definePerson(e), { type: "list-item" }),
        ),
      ),
    );
  }

  if (substituteExecutors.length === 1) {
    if (executors.length === 1) {
      slate.push(
        makeBlock(
          [
            makeText(
              `If for any reason, my Executor is unable or unwilling to act as executor and trustee of this Will, I appoint`,
              { space: "end" },
            ),
            ...utils.definePerson(substituteExecutors[0] || ""),
            makeText(`as the sole executor and trustee of this Will`, {
              space: "both",
            }),
            ...makeDefinition(`Executor`, {
              period: true,
            }),
          ],
          { type: "list-item" },
        ),
      );
    } else if (executors.length > 1) {
      slate.push(
        makeBlock(
          [
            makeText(
              `If for any reason, any one of my Executors is unable or unwilling to act as executor and trustee of this Will, I appoint`,
              { space: "end" },
            ),
            ...utils.definePerson(substituteExecutors[0] || ""),
            makeText(
              `as alternative Executor to act jointly with the remaining Executors appointed above`,
              { space: "both" },
            ),
            ...makeDefinition(
              [
                "my",
                `Executor`,
                `and jointly with the Executors named above`,
                `Executors`,
              ],
              {
                period: true,
              },
            ),
          ],
          { type: "list-item" },
        ),
      );
    }
  }

  if (substituteExecutors.length > 1) {
    if (executors.length === 1) {
      slate.push(
        makeBlock(
          [
            makeText(
              `If for any reason, my Executor is unable or unwilling to act as executor and trustee of this Will, I appoint any one of the following persons (listed in the specified order of preference) as Executor in his/her place as the sole executor and trustee of this Will`,
            ),
            ...makeDefinition(`Executor`, {
              space: "start",
            }),
            makeText(
              `, provided always that the said person is willing and able to act as executor and is not already an Executor:`,
            ),
          ],
          { type: "list-item" },
        ),
        makeSubListBlock(
          substituteExecutors.map((e: string) =>
            makeBlock(utils.definePerson(e), { type: "list-item" }),
          ),
        ),
      );
    } else if (executors.length > 1) {
      slate.push(
        makeBlock(
          [
            makeText(
              `If for any reason, any one of my Executors is unable or unwilling to act as executor and trustee of this Will, I appoint any one of the following persons (listed in the specified order of preference) as executor in his/her place to act jointly with the remaining Executors appointed above`,
            ),
            ...makeDefinition(
              [
                "my",
                `Executor`,
                `and jointly with the Executors named above`,
                `Executors`,
              ],
              {
                space: "both",
              },
            ),
            makeText(
              `, provided always that the said person is willing and able to act as executor and is not already an Executor:`,
            ),
          ],
          { type: "list-item" },
        ),
        makeSubListBlock(
          substituteExecutors.map((e: string) =>
            makeBlock(utils.definePerson(e), { type: "list-item" }),
          ),
        ),
      );
    }
  }

  const isPlural = executors.length > 1;
  const ExecutorTitle = isPlural ? "Executors" : "Executor";
  const heOrSheOrThey = isPlural ? "they" : "he/she";
  const hisOrHerOrTheir = isPlural ? "their" : "his/her";

  slate.push(
    makeBlock(
      [
        makeText(
          `My ${ExecutorTitle} shall have full powers to give, sell for cash or upon such terms as ${heOrSheOrThey} may deem fit, call in and convert into money, any of my Assets or such part or parts thereof as shall be of a saleable or convertible nature, at such time or times and in such manner as my ${ExecutorTitle} shall, in ${hisOrHerOrTheir} absolute and uncontrolled discretion think fit, with power to postpone such sale, calling in and conversion of such property, or of such part or parts thereof for such period or periods as my ${ExecutorTitle} in  ${hisOrHerOrTheir} absolute and uncontrolled discretion think fit, and to the extent necessary, have full powers to pay all my liabilities, debts, mortgages, funeral and testamentary expenses, and any taxes payable by reason of my death from my Estate`,
          { space: "end" },
        ),
        ...makeDefinition("Expenses", { period: true }),
      ],
      { type: "list-item" },
    ),
    makeBlock(
      [
        makeText(
          `The powers of my ${ExecutorTitle} named herein shall be in addition and in modification of the Executor's powers under the Trustees Act (Cap. 337) of Singapore, any re-enactment thereof and general terms of the laws of Singapore. For the avoidance of doubt, part IVA and IVB of the Trustees Act (Cap. 337) shall apply.`,
        ),
      ],
      { type: "list-item" },
    ),
  );
};

export const makeTestamentaryGuardianParagraphs = (
  slate: any[],
  utils: TUtils,
) => {
  const { testamentaryGuardians = [], substituteTestamentaryGuardians = [] } =
    utils.data;

  if (testamentaryGuardians.length === 0) return;

  console.log("testamentaryGuardians", {
    substituteTestamentaryGuardians,
    testamentaryGuardians,
  });

  const hasSpouse =
    utils.user.martialStatus === MARTIAL_STATUS.MARRIED_CHILDREN ||
    utils.user.martialStatus === MARTIAL_STATUS.MARRIED_NO_CHILDREN ||
    utils.user.martialStatus === MARTIAL_STATUS.MARRIED_UNDERAGED_CHILDREN;

  const isPlural = testamentaryGuardians.length > 1;
  const GuardianTitle = isPlural ? "Guardians" : "Guardian";
  const heOrSheOrThey = isPlural ? "they" : "he/she";
  const hisOrHerOrTheir = isPlural ? "their" : "his/her";

  if (testamentaryGuardians.length === 1) {
    slate.push(
      makeBlock(
        [
          makeText(`It is my wish that`, { space: "end" }),
          ...utils.definePerson(testamentaryGuardians[0] || ""),
          makeText(`be appointed as guardian of my child/children`, {
            period: true,
            space: "both",
          }),
        ],
        { type: "list-item" },
      ),
    );
  }

  if (testamentaryGuardians.length > 1) {
    slate.push(
      makeBlock(
        [
          makeText(
            `It is my wish that the following persons be appointed as joint guardians of my child/children`,
            { space: "end" },
          ),
          ...makeDefinition([
            `each my`,
            `Guardian`,
            `and collectively my`,
            `Guardians`,
          ]),
          makeText(`:`),
        ],
        { type: "list-item" },
      ),
      makeSubListBlock(
        testamentaryGuardians.map((e: string) =>
          makeBlock(utils.definePerson(e), { type: "list-item" }),
        ),
      ),
    );
  }

  if (substituteTestamentaryGuardians.length === 1) {
    if (testamentaryGuardians.length === 1) {
      slate.push(
        makeBlock(
          [
            makeText(
              `If for any reason, my Guardian is unable or unwilling to act as guardian of my child/children, I appoint`,
              { space: "end" },
            ),
            ...utils.definePerson(substituteTestamentaryGuardians[0] || ""),
            makeText(`as guardian of my child/children`, {
              period: true,
              space: "both",
            }),
          ],
          { type: "list-item" },
        ),
      );
    } else if (testamentaryGuardians.length > 1) {
      slate.push(
        makeBlock(
          [
            makeText(
              `If for any reason, any one of my Guardians is unable or unwilling to act as guardian to my child/children, I appoint`,
              { space: "end" },
            ),
            ...utils.definePerson(testamentaryGuardians[0] || ""),
            makeText(
              `as guardian to my child/children in his/her place to act jointly with the remaining Guardians appointed above`,
              { space: "both" },
            ),
            ...makeDefinition(
              [
                "my",
                `Guardian`,
                `and jointly with the Executors named above`,
                `Guardians`,
              ],
              {
                period: true,
              },
            ),
          ],
          { type: "list-item" },
        ),
      );
    }
  }

  if (substituteTestamentaryGuardians.length > 1) {
    if (testamentaryGuardians.length === 1) {
      slate.push(
        makeBlock(
          [
            makeText(
              `If for any reason, my Guardian is unable or unwilling to act as guardian to my child/children, I appoint any one of the following persons (listed in the specified order of preference) as guardian to my child/children in her/her place`,
            ),
            ...makeDefinition(`Guardian`, {
              space: "start",
            }),
            makeText(
              `, provided always that the said person is willing and able to act as guardian and is not already an Guardian:`,
            ),
          ],
          { type: "list-item" },
        ),
        makeSubListBlock(
          substituteTestamentaryGuardians.map((e: string) =>
            makeBlock(utils.definePerson(e), { type: "list-item" }),
          ),
        ),
      );
    } else if (testamentaryGuardians.length > 1) {
      slate.push(
        makeBlock(
          [
            makeText(
              `If for any reason, any one of my Guardians is unable or unwilling to act as guardian to my child/children, I appoint any one of the following persons (listed in the specified order of preference) as guardian to my child/children in his/her place to act jointly with the remaining Guardians appointed above`,
            ),
            ...makeDefinition(
              [
                "my",
                `Guardian`,
                `and jointly with the guardians named above`,
                `Guardians`,
              ],
              {
                space: "both",
              },
            ),
            makeText(
              `, provided always that the said person is willing and able to act as guardian and is not already a Guardian:`,
            ),
          ],
          { type: "list-item" },
        ),
        makeSubListBlock(
          substituteTestamentaryGuardians.map((e: string) =>
            makeBlock(utils.definePerson(e), { type: "list-item" }),
          ),
        ),
      );
    }
  }

  if (hasSpouse) {
    slate.push(
      makeBlock(
        [
          makeText(
            `Where my spouse is alive and is able and willing to act as guardian of my child/children, my ${GuardianTitle} shall act jointly with my spouse as joint guardians of my child/children.`,
          ),
        ],
        { type: "list-item" },
      ),
    );
  }
};

export const makeDistributionParagraphs = (
  slate: any[],
  utils: TUtils & {
    distribution: TWillFormSchema["distributions"][number];
    hasSpecificDistributions: boolean;
  },
  cache: Record<string, string> = {},
  _assetId: string | null,
) => {
  const { distribution, hasSpecificDistributions } = utils;

  const assetId = _assetId || distribution.asset;

  if (!distribution) return;

  /**
   * Utilities
   */

  const getEntityString = ({ entityId }: { entityId: string }) => {
    const entity = utils.entities.find((a) => a._id === entityId);
    const entityTitle = entity?.type
      ? UserEntityDefinitionTransformsMap[entity.type](entity as any)
      : "Err Entity";
    const entityDescription = entity?.type
      ? UserEntityDefineTransformsMap[entity.type](entity as any)
      : "Err Entity";

    switch (entity?.type) {
      case EntityTypeEnum.CHARITY:
        if (cache[entityId]) return cache[entityId];
        cache[entityId] = entityTitle;
        return `the ${entityDescription} ("${entityTitle}")`;
      case EntityTypeEnum.PERSON:
        if (cache[entityId]) return cache[entityId];
        cache[entityId] = entityTitle;
        return `my ${entityDescription} ("${entityTitle}")`;
      default:
        return "Err Entity";
    }
  };

  const isPercentage = isOfDistributionType("Percentage")(distribution);

  if (distribution.assetAllocationType === "Whole") {
    slate.push(
      makeBlock(
        [
          makeText(`Subject to the payment of all my Expenses, I give`, {
            space: "end",
          }),
          ...utils.defineAsset(assetId, hasSpecificDistributions),
          makeText(`to`, { space: "both" }),
          ...utils.definePerson(distribution.beneficiary),
          makeText(`absolutely and free from all encumbrances`, {
            period: true,
            space: "start",
          }),
        ],
        { type: "list-item" },
      ),
    );
    distribution.substituteBeneficiaries.forEach((b, i, arr) => {
      const isFirstSubstitute = i === 0;

      slate.push(
        makeBlock(
          [
            makeText(`If`, { space: "end" }),
            ...utils.definePerson(
              isFirstSubstitute ? distribution.beneficiary : arr[i - 1],
            ),
            makeText(
              `should die during my lifetime, or fail to survive me for more than thirty (30) days, then i give`,
              { space: "both" },
            ),
            ...utils.defineAsset(assetId, hasSpecificDistributions),
            makeText(`to`, { space: "both" }),
            ...utils.definePerson(b),
            makeText(
              `so long as he/she survives me for more than thirty (30) days:`,
              { space: "start" },
            ),
          ],
          { type: "list-item" },
        ),
      );
    });
    return;
  }

  if (distribution.allocations.length === 1) {
    const allocation = distribution.allocations[0];

    slate.push(
      makeBlock(
        [
          makeText(`Subject to the payment of all my Expenses, I give`, {
            space: "end",
          }),
          ...utils.defineAsset(assetId, hasSpecificDistributions),
          makeText(`to`, { space: "both" }),
          ...utils.definePerson(allocation.beneficiary),
          makeText(`absolutely and free from all encumbrances`, {
            period: true,
            space: "start",
          }),
        ],
        { type: "list-item" },
      ),
    );
  }

  if (distribution.allocations.length > 1) {
    slate.push(
      makeBlock(
        [
          makeBlock([
            makeText(`Subject to the payment of all my Expenses,`, {
              space: "end",
            }),

            ...(isPercentage
              ? [
                  makeText(`I give`, {
                    space: "end",
                  }),
                  ...utils.defineAsset(assetId, hasSpecificDistributions),
                  makeText(
                    `to the following persons absolutely and free from all encumbrances in the following proportions:`,
                    { space: "start" },
                  ),
                ]
              : [
                  makeText(`I give the following amounts from`, {
                    space: "end",
                  }),

                  ...utils.defineAsset(assetId, hasSpecificDistributions),
                  makeText(
                    `to the following persons absolutely and free from all encumbrances:`,
                    {
                      space: "start",
                    },
                  ),
                ]),
          ]),
          makeSubListBlock(
            distribution.allocations.map((a) =>
              makeBlock(
                [
                  isPercentage
                    ? makeText(`${a.allocation}% to`, {
                        space: "end",
                      })
                    : makeText(`SGD $${a.allocation} to`, {
                        space: "end",
                      }),
                  ...utils.definePerson(a.beneficiary),
                ],
                {
                  type: "list-item",
                },
              ),
            ),
          ),
        ],
        { type: "list-item" },
      ),
    );
  }

  const makeSubstituteAllocationParagaphs = (
    slate: any[],
    utils: TUtils & {
      allocations: TPercentageOnlyAllocation[] | TAmountOnlyAllocation[];
      hasSpecificDistributions: boolean;
    },
  ) => {
    utils.allocations.forEach((a) => {
      const entityString = getEntityString({
        entityId: a.beneficiary,
      });

      if (a.substituteAllocationMode === ALLOCATION_MODE_ENUM.EQUALLY) {
        slate.push(
          makeBlock(
            [
              makeText(`If`, {
                space: "end",
              }),
              ...utils.definePerson(a.beneficiary),
              makeText(
                `should die during my lifetime, or fail to survive me for more than thirty (30) days, then the`,
                { space: "both" },
              ),
              ...utils.defineAsset(assetId, utils.hasSpecificDistributions),
              makeText(
                `which he/she would otherwise have received under this Will shall be distributed equally amongst the following people free from all encumbrances so long as they survive me for more than thirty (30) days:`,
                { space: "both" },
              ),
              makeSubListBlock(
                a.substituteAllocations.map((a) =>
                  makeBlock(utils.definePerson(a.beneficiary), {
                    type: "list-item",
                  }),
                ),
              ),
            ],
            { type: "list-item" },
          ),
        );
      }

      if (
        a.substituteAllocationMode ===
        ALLOCATION_MODE_ENUM.EQUALLY_AMONGST_CHILDREN
      ) {
        slate.push(
          makeBlock(
            [
              makeText(`If`, {
                space: "end",
              }),
              ...utils.definePerson(a.beneficiary),
              makeText(
                `should die during my lifetime, or fail to survive me for more than thirty (30) days, then the`,
                { space: "both" },
              ),
              ...utils.defineAsset(assetId, utils.hasSpecificDistributions),
              makeText(
                `which he/she would otherwise have received under this Will shall be distributed equally amongst his/her surviving issues absolutely and free from all encumbrances so long as they survive me for more than thirty (30) days.`,
                { space: "both" },
              ),
            ],
            { type: "list-item" },
          ),
        );
      }

      if (a.substituteAllocationMode === ALLOCATION_MODE_ENUM.CUSTOM_PERCENT) {
        slate.push(
          makeBlock(
            [
              makeText(`If`, {
                space: "end",
              }),
              ...utils.definePerson(a.beneficiary),
              makeText(
                `should die during my lifetime, or fail to survive me for more than thirty (30) days, then the proportion of`,
                { space: "both" },
              ),
              ...utils.defineAsset(assetId, utils.hasSpecificDistributions),
              makeText(
                `which he/she would otherwise have received under this Will shall be distributed amongst the following people, in the following proportion free from all encumbrances so long as they survive me for more than thirty (30) days:`,
                { space: "both" },
              ),
              makeSubListBlock(
                a.substituteAllocations.map((a) =>
                  makeBlock(
                    [
                      makeText(`${a.allocation}% to `),
                      ...utils.definePerson(a.beneficiary),
                    ],
                    {
                      type: "list-item",
                    },
                  ),
                ),
              ),
            ],
            { type: "list-item" },
          ),
        );

        makeSubstituteAllocationParagaphs(slate, {
          ...utils,
          allocations: a.substituteAllocations,
        });
      }

      if (a.substituteAllocationMode === ALLOCATION_MODE_ENUM.CUSTOM_AMOUNT) {
        slate.push(
          makeBlock(
            [
              makeText(`If`, {
                space: "end",
              }),
              ...utils.definePerson(a.beneficiary),
              makeText(
                `should die during my lifetime, or fail to survive me for more than thirty (30) days, then the amount from`,
                { space: "both" },
              ),
              ...utils.defineAsset(assetId, utils.hasSpecificDistributions),
              makeText(
                `which he/she would otherwise have received under this Will shall be distributed amongst the following people, in the following proportion free from all encumbrances so long as they survive me for more than thirty (30) days:`,
                { space: "both" },
              ),
              makeSubListBlock(
                a.substituteAllocations.map((a) =>
                  makeBlock(
                    [
                      makeText(`$${a.allocation} to `),
                      ...utils.definePerson(a.beneficiary),
                    ],
                    {
                      type: "list-item",
                    },
                  ),
                ),
              ),
            ],
            { type: "list-item" },
          ),
        );
        makeSubstituteAllocationParagaphs(slate, {
          ...utils,
          allocations: a.substituteAllocations,
        });
      }
    });
  };

  makeSubstituteAllocationParagaphs(slate, {
    allocations: distribution.allocations,
    ...utils,
    hasSpecificDistributions,
  });
};

export const makeWitnessParagraphs = (slate: any[], utils: TUtils) => {
  const { lawyerReview, witnesses } = utils.data;

  const hisHer = utils.user.gender === "Male" ? "his" : "her";

  slate.push(
    makeBlock(
      [
        makeText(`SIGNED`, { bold: true, space: "end" }),
        makeText(`by the abovenamed`, { space: "end" }),
        ...utils.definePerson(utils.userId),
        makeText(
          `on ______________________  as ${hisHer} last Will and Testament in the presence of us being present at the same time and who at ${hisHer} request in ${hisHer} presence and in the presence of each other have hereunto subscribed our names as witnesses.`,
          { space: "both" },
        ),
      ],
      { type: "signature" },
    ),
  );

  if (lawyerReview === WillLawerReviewChoices.NO) {
    slate.push(
      makeWitnessBlock([], {
        type: "double-signature",
      }),
    );
  } else {
    const signatories =
      witnesses?.map((p) => {
        const person = utils.entities.find((e) => e._id === p);
        if (person?.type === "Charity") {
          return {
            name: "Err",
            idDocument: "Err",
            idNumber: "Err",
          };
        }

        if (person?.type === "Person")
          return {
            name: person?.name,
            idDocument: person?.identificationDocument,
            idNumber: person?.identificationNumber,
          };

        return {
          name: "Err",
          idDocument: "Err",
          idNumber: "Err",
        };
      }) || [];

    slate.push(makeWitnessBlock(signatories, { type: "double-signature" }));
  }

  if (lawyerReview === WillLawerReviewChoices.CN)
    slate.push(
      makeBlock(
        [
          makeText(`This Will having first been read over to the abovenamed`, {
            space: "end",
          }),
          ...utils.definePerson(utils.userId),
          makeText(
            `and interpreted in Mandarin to the same who understands Mandarin but has no knowledge of and cannot read the English language by me ______________________ who understands both the English and Mandarin language which interpretation was done in our presence when the said`,
            { space: "both" },
          ),
          ...utils.definePerson(utils.userId),
          makeText(
            `appeared thoroughly to understand the Will and to approve the contents thereof was signed by the abovenamed`,
            { space: "both" },
          ),
          ...utils.definePerson(utils.userId),
          makeText(``, { period: true }),
        ],
        { type: "paragraph" },
      ),
    );
};

// export const makeWitnessParagraphs = (slate: any[], utils: TUtils) => {
//   const { lawyerReview, witnesses } = utils.data;

//   const hisHer = utils.user.gender === "Male" ? "his" : "her";

//   const signatories =
//     lawyerReview === WillLawerReviewChoices.NO
//       ? []
//       : witnesses?.map((p) => {
//           const person = utils.entities.find((e) => e._id === p);
//           if (person?.type === "Charity") {
//             return {
//               name: "Err",
//               idDocument: "Err",
//               idNumber: "Err",
//             };
//           }

//           if (person?.type === "Person")
//             return {
//               name: person?.name,
//               idDocument: person?.identificationDocument,
//               idNumber: person?.identificationNumber,
//             };

//           return {
//             name: "Err",
//             idDocument: "Err",
//             idNumber: "Err",
//           };
//         }) || [];

//   slate.push(
//     makeExecutionBlock(
//       [
//         makeText(`SIGNED`, { bold: true, space: "end" }),
//         makeText(`by the abovenamed`, { space: "end" }),
//         ...utils.definePerson(utils.userId),
//         makeText(
//           `as ${hisHer} last Will and Testament in the presence of us being present at the same time and who at ${hisHer} request in ${hisHer} presence and in the presence of each other have hereunto subscribed our names as witnesses.`,
//           { space: "both" },
//         ),
//       ],
//       { type: "execution-block", signatories },
//     ),
//   );

//   lawyerReview === WillLawerReviewChoices.CN &&
//     slate.push(
//       makeBlock(
//         [
//           makeText(`This Will having first been read over to the abovenamed`, {
//             space: "end",
//           }),
//           ...utils.definePerson(utils.userId),
//           makeText(
//             `and interpreted in Mandarin to the same who understands Mandarin but has no knowledge of and cannot read the English language by me ______________________ who understands both the English and Mandarin language which interpretation was done in our presence when the said`,
//             { space: "both" },
//           ),
//           ...utils.definePerson(utils.userId),
//           makeText(
//             `appeared thoroughly to understand the Will and to approve the contents thereof was signed by the abovenamed`,
//             { space: "both" },
//           ),
//           ...utils.definePerson(utils.userId),
//           makeText(``, { period: true }),
//         ],
//         { type: "paragraph" },
//       ),
//     );
// };

export const makeInstructionsParagraphs = (slate: any[], utils: TUtils) => {
  const {
    funeralInstructions,
    funeralDuration,
    funeralLocation,
    burialInstructions,
    cemeteryLocation,
    inlandScatteringLocation,
    crematoriumLocation,
    seaScatteringLocation,
    columbariumLocation,
  } = utils.data;

  /**
   * Funeral Instruction
   */

  if (
    funeralInstructions &&
    funeralInstructions !== FUNERAL_INSTRUCTIONS_CHOICES.NO
  ) {
    const getFuneralInstructionString = (
      funeralInstructions: FUNERAL_INSTRUCTIONS_CHOICES,
    ) => {
      switch (funeralInstructions) {
        case FUNERAL_INSTRUCTIONS_CHOICES.BUDDHIST: {
          return "Buddhist funeral";
        }
        case FUNERAL_INSTRUCTIONS_CHOICES.CATHOLIC: {
          return "Catholic funeral";
        }
        case FUNERAL_INSTRUCTIONS_CHOICES.CHRISTIAN: {
          return "Christian funeral";
        }
        case FUNERAL_INSTRUCTIONS_CHOICES.NON_RELIGIOUS: {
          return "secular funeral";
        }
        case FUNERAL_INSTRUCTIONS_CHOICES.HINDU: {
          return "Hindu funeral";
        }
        case FUNERAL_INSTRUCTIONS_CHOICES.SIKH: {
          return "Sikh funeral";
        }
        case FUNERAL_INSTRUCTIONS_CHOICES.TAOIST: {
          return "Taoist funeral";
        }
        default: {
          return "Err";
        }
      }
    };

    slate.push(
      makeBlock(
        makeText(
          `I wish to have a ${getFuneralInstructionString(
            funeralInstructions,
          )} held for me for ${funeralDuration} days at ${funeralLocation}.`,
        ),
        { type: "list-item" },
      ),
    );
  }

  /**
   * Burial Instruction
   */

  switch (burialInstructions) {
    case BURIAL_INSTRUCTIONS_CHOICES.BURIAL: {
      slate.push(
        makeBlock(
          makeText(
            `I wish to be buried in Singapore at the ${cemeteryLocation}.`,
          ),
          { type: "list-item" },
        ),
      );
      break;
    }
    case BURIAL_INSTRUCTIONS_CHOICES.CREMATED_SCATTERED_GROUND: {
      slate.push(
        makeBlock(
          makeText(
            `I wish to be cremated at ${crematoriumLocation} and my ashes scattered at the ${inlandScatteringLocation}.`,
          ),
          { type: "list-item" },
        ),
      );
      break;
    }
    case BURIAL_INSTRUCTIONS_CHOICES.CREMATED_SCATTERED_SEA: {
      slate.push(
        makeBlock(
          makeText(
            `I wish to be cremated at ${crematoriumLocation} and my ashes scattered at ${seaScatteringLocation}.`,
          ),
          { type: "list-item" },
        ),
      );
      break;
    }
    case BURIAL_INSTRUCTIONS_CHOICES.CREMATED_COLUMBARIUM: {
      slate.push(
        makeBlock(
          makeText(
            `I wish to be cremated at ${crematoriumLocation} and my ashes kept at ${columbariumLocation}.`,
          ),
          { type: "list-item" },
        ),
      );
      break;
    }
  }
};

export function GenerateWill(utils: TGenerateArg) {
  const slate: Block[] = [];
  const cache = {};
  const assetCache: Record<string, Text[]> = {};
  const personCache: Record<string, Text[]> = {};

  const getAssetTitle = ({ assetId }: { assetId: string | null }) => {
    const asset = utils.assets.find((a) => a._id === assetId);

    const assetTitle = asset?.type
      ? UserAssetDefinitionTransformsMap[asset.type](asset as any)
      : "Err Asset";

    return assetTitle;
  };

  const getAssetDescription = ({ assetId }: { assetId: string | null }) => {
    const asset = utils.assets.find((a) => a._id === assetId);

    const assetDescription = asset?.type
      ? UserAssetDefineTransformsMap[asset.type](asset as any)
      : "Err Asset";

    return assetDescription;
  };

  const defineAsset: TDefineAsset = (
    _assetId,
    hasSpecificDistributions,
  ): Text[] => {
    const assetId = _assetId || "RESIDUAL_ASSET";

    const cachedValue = assetCache[assetId];
    if (cachedValue !== undefined) return cachedValue;

    if (assetId === "RESIDUAL_ASSET") {
      assetCache[assetId] = makeDefinition("Residual Assets");
      return [
        makeText(
          `all my Assets in Singapore ${
            hasSpecificDistributions
              ? "not distributed in the clauses above"
              : ""
          }`,
          {
            space: "end",
          },
        ),
        ...makeDefinition("Residual Assets"),
      ];
    }

    const asset = utils.assets.find((a) => a._id === assetId);

    if (!asset) {
      throw Error("Asset Not Found");
    }

    const assetTitle = getAssetTitle({ assetId });

    const assetDescription = getAssetDescription({ assetId });

    const result = [
      makeText(`${assetDescription}`, { space: "end" }),
      ...makeDefinition(assetTitle),
    ];

    assetCache[assetId] = makeDefinition(assetTitle);

    return result;
  };

  const definePerson: TDefinePerson = (entityId: string): Text[] => {
    const cachedValue = personCache[entityId];
    if (cachedValue !== undefined) return cachedValue;

    if (entityId === utils.userId) {
      const userTitle = UserDetailsDefinitionTransform(utils.user);
      const userDescription = UserDetailsDefineTransform(utils.user);

      const result = [
        makeText(`${userDescription}`, { space: "end" }),
        ...makeDefinition("Testator"),
      ];

      personCache[entityId] = [
        {
          text: "Testator",
          bold: true,
        },
      ];

      return result;
    }

    const entity = utils.entities.find((a) => a._id === entityId);

    if (!entity) {
      throw Error(`${entityId} Entity Not Found`);
    }

    const entityTitle = entity?.type
      ? UserEntityDefinitionTransformsMap[entity.type](entity as any)
      : "Err Asset";
    const entityDescription = entity?.type
      ? UserEntityDefineTransformsMap[entity.type](entity as any)
      : "Err Asset";

    const result = [
      makeText(`${entityDescription} `),
      makeText(`("`),
      makeText(entityTitle, { bold: true }),
      makeText(`")`),
    ];

    personCache[entityId] = [
      {
        text: entityTitle,
        bold: true,
      },
    ];

    return result;
  };

  if (utils.resource.type === ORDER_TYPE.WILL) {
    const data = utils.resource.data;
    const extendedUtils: TUtils = {
      ...utils,
      defineAsset,
      definePerson,
      getAssetTitle,
      getAssetDescription,
      data,
    };

    makeInitialParagraphs(slate, extendedUtils);
    makeExecutorParagraphs(slate, extendedUtils);
    makeTestamentaryGuardianParagraphs(slate, extendedUtils);

    data.distributions?.forEach((d) =>
      makeDistributionParagraphs(
        slate,
        {
          ...extendedUtils,
          distribution: d,
          hasSpecificDistributions: data.distributions.length > 1,
        },
        cache,
        d.asset,
      ),
    );
    makeInstructionsParagraphs(slate, extendedUtils);
    makeWitnessParagraphs(slate, extendedUtils);
  }

  return [makeBlock(slate, { type: "ordered-list" })];
}
